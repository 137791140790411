import React, { useEffect, useMemo, useState } from 'react';

import styled, { css } from 'styled-components';

import {
  VLIVE_ENABLED_MODEL_TABS_LOKALISE_MAP,
  VLIVE_ENABLED_MODEL_TABS_ORDER,
} from '@17live/app/components/VLiveCharacter/VLiveCharacter.constants';
import VLiveCharacterIntroductionFromAppEasyCreationByImage from '@17live/app/components/VLiveCharacter/VLiveCharacterIntroductionFromAppEasyCreationByImage';
import VLiveCharacterIntroductionFromAppLive2D from '@17live/app/components/VLiveCharacter/VLiveCharacterIntroductionFromAppLive2D';
import VLiveCharacterIntroductionFromAppVRM from '@17live/app/components/VLiveCharacter/VLiveCharacterIntroductionFromAppVRM';
import { VliverModel } from '@17live/app/enums/Vliver.enums';
import { $t } from '@17live/app/utils';
import { COLOR_NEUTRAL_200 } from '@17live/design-system/color';
import TabList from '@17live/ui/TabList/TabList';

const VLiveCharacterIntroductionFromAppWrapper = styled.div<{
  hasTab: boolean;
}>`
  ${props =>
    props.hasTab &&
    css`
      border: 1px solid ${COLOR_NEUTRAL_200};
      border-radius: 4px;
    `};
`;

type VLiveCharacterIntroductionFromAppProps = {
  characterSizeLimitMB?: number;
  easyCreationImageSizeLimitMB?: number;
  enabledModels: VliverModel[];
};

const VLiveCharacterIntroductionFromApp = ({
  characterSizeLimitMB,
  easyCreationImageSizeLimitMB,
  enabledModels,
}: VLiveCharacterIntroductionFromAppProps) => {
  const [activeTab, setActiveTab] = useState<VliverModel | null>(null);

  const tabs: VliverModel[] = useMemo(() => {
    const result: VliverModel[] = [];

    VLIVE_ENABLED_MODEL_TABS_ORDER.forEach(model => {
      if (enabledModels.find(enabledModel => enabledModel === model)) {
        result.push(model);
      }
    });

    return result;
  }, [enabledModels]);

  // we need to transfer the enum of v-liver mode to the index of TabList
  const hasTab = tabs.length >= 2;
  const tabListName = tabs.map(tab =>
    $t(VLIVE_ENABLED_MODEL_TABS_LOKALISE_MAP[tab])
  );
  const activeTabIndex = tabs.findIndex(tab => tab === activeTab);

  const handleClickTab = (index: number) => {
    setActiveTab(tabs[index]);
  };

  useEffect(() => {
    setActiveTab(tabs[0]);
  }, [tabs]);

  return (
    <VLiveCharacterIntroductionFromAppWrapper hasTab={hasTab}>
      {hasTab && (
        <TabList
          activeTab={activeTabIndex}
          handleClickTab={handleClickTab}
          tabs={tabListName}
        />
      )}
      {activeTab === VliverModel.LIVE2D && (
        <VLiveCharacterIntroductionFromAppLive2D
          characterSizeLimitMB={characterSizeLimitMB}
          hasTab={hasTab}
        />
      )}
      {activeTab === VliverModel.VRM && (
        <VLiveCharacterIntroductionFromAppVRM hasTab={hasTab} />
      )}
      {activeTab === VliverModel.EASY_CREATION_BY_IMAGE && (
        <VLiveCharacterIntroductionFromAppEasyCreationByImage
          easyCreationImageSizeLimitMB={easyCreationImageSizeLimitMB}
          hasTab={hasTab}
        />
      )}
    </VLiveCharacterIntroductionFromAppWrapper>
  );
};

export default VLiveCharacterIntroductionFromApp;
