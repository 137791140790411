import React from 'react';

import styled from 'styled-components';

import VLiveCharacterIntroductionFileInfoEasyCreationByImage from '@17live/app/components/VLiveCharacter/VLiveCharacterIntroductionFileInfoEasyCreationByImage';
import { $t, getGCSImage, getWebp2xURL } from '@17live/app/utils';
import {
  COLOR_NEUTRAL_700,
  COLOR_PRIMARY_BLACK,
} from '@17live/design-system/color';
import Box from '@17live/ui/Box';
import IgVliverEasyCreationByImageTutorial from '@17live/ui/Gui/IgVliverEasyCreationByImageTutorial';

const Subtitle = styled.h6`
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  margin: 0 0 8px;
  color: ${COLOR_PRIMARY_BLACK};
`;

const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  margin: 0;
  color: ${COLOR_NEUTRAL_700};
  white-space: pre-wrap;
`;

type VLiveCharacterIntroductionFromAppEasyCreationByImageProps = {
  easyCreationImageSizeLimitMB?: number;
  hasTab: boolean;
};

const Image = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const VLiveCharacterIntroductionFromAppEasyCreationByImage = ({
  easyCreationImageSizeLimitMB,
  hasTab,
}: VLiveCharacterIntroductionFromAppEasyCreationByImageProps) => {
  return (
    <Box mt={'24px'} p={hasTab ? '0 16px' : '0px'}>
      <Subtitle>{$t('vlive_intro_pic_title')}</Subtitle>
      <Box mb={'8px'}>
        <IgVliverEasyCreationByImageTutorial width={160} height={160} />
      </Box>
      <Box mb={'24px'}>
        <Box mb={'24px'}>
          <Text>{$t('vlive_intro_pic_content_1')}</Text>
        </Box>
        <VLiveCharacterIntroductionFileInfoEasyCreationByImage
          isMobile
          easyCreationImageSizeLimitMB={easyCreationImageSizeLimitMB}
        />
      </Box>
      <Box mb={'24px'}>
        <Subtitle>{$t('vlive_intro_pic_content_3')}</Subtitle>
        <Box mb={'8px'}>
          <Image
            width={311}
            height={311}
            src={getWebp2xURL(getGCSImage('Vliver_FAQ_picV_format.png'))}
          />
        </Box>
        <Text>{$t('vlive_intro_pic_content_3_1')}</Text>
        <Text>{$t('vlive_intro_pic_content_3_2')}</Text>
        <Text>{$t('vlive_intro_pic_content_3_3')}</Text>
      </Box>
      <Subtitle>{$t('vlive_intro_pic_content_3_4')}</Subtitle>
      <Box mb={'24px'}>
        <Image
          width={311}
          height={311}
          src={getWebp2xURL(getGCSImage('ig_Vliver_FAQ_picV_OK.png'))}
        />
      </Box>
      <Subtitle>{$t('vlive_intro_pic_content_3_5')}</Subtitle>
      <Box mb={'24px'}>
        <Box mb={'8px'}>
          <Image
            width={311}
            height={311}
            src={getWebp2xURL(getGCSImage('ig_Vliver_FAQ_picV_NG.png'))}
          />
        </Box>
        <Text>{$t('vlive_intro_pic_content_3_5_1')}</Text>
        <Text>{$t('vlive_intro_pic_content_3_5_2')}</Text>
        <Text>{$t('vlive_intro_pic_content_3_5_3')}</Text>
        <Text>{$t('vlive_intro_pic_content_3_5_4')}</Text>
        <br />
        <Text>{$t('vlive_intro_pic_content_3_5_5')}</Text>
      </Box>
      <Box mb={'24px'}>
        <Subtitle>{$t('vlive_intro_pic_content_4')}</Subtitle>
        <Box mb={'8px'}>
          <Image
            width={311}
            height={200}
            src={getWebp2xURL(getGCSImage('ig_Vliver_image_tiips1_Deco.png'))}
          />
        </Box>
        <Box mb={'8px'}>
          <Text>{$t('vlive_intro_pic_content_4_1')}</Text>
        </Box>
        <Box mb={'8px'}>
          <Image
            width={311}
            height={200}
            src={getWebp2xURL(getGCSImage('ig_Vliver_image_tiips2_hair.png'))}
          />
        </Box>
        <Box mb={'8px'}>
          <Text>{$t('vlive_intro_pic_content_4_2')}</Text>
        </Box>
        <Box mb={'8px'}>
          <Image
            width={311}
            height={200}
            src={getWebp2xURL(getGCSImage('ig_Vliver_image_tiips3_eye.png'))}
          />
        </Box>
        <Box mb={'8px'}>
          <Text>{$t('vlive_intro_pic_content_4_3')}</Text>
        </Box>
        <Box mb={'8px'}>
          <Image
            width={311}
            height={200}
            src={getWebp2xURL(getGCSImage('ig_Vliver_image_tiips4_face.png'))}
          />
        </Box>
        <Box mb={'8px'}>
          <Text>{$t('vlive_intro_pic_content_4_4')}</Text>
        </Box>
        <Box mb={'8px'}>
          <Image
            width={311}
            height={200}
            src={getWebp2xURL(getGCSImage('ig_Vliver_image_tiips5_mouth.png'))}
          />
        </Box>
        <Box mb={'8px'}>
          <Text>{$t('vlive_intro_pic_content_4_5')}</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default VLiveCharacterIntroductionFromAppEasyCreationByImage;
