import {
  VliverModel,
  VliverValidationErrorBit,
} from '@17live/app/enums/Vliver.enums';

export const VLIVE_CHARACTER_WIDTH = 126;
export const VLIVE_CHARACTER_HEIGHT = 224;

export const VLIVE_CHARACTER_ALERT_WIDTH = 90;
export const VLIVE_CHARACTER_ALERT_HEIGHT = 160;

export const VLIVE_REMOVE_SUCCESS_TOAST_DURATION: number = 3000; // ms
export const VLIVE_UPLOAD_MAX_ERROR_TOAST_DURATION: number = 3000; // ms
export const VLIVE_UPLOAD_ERROR_TOAST_DURATION: number = 4000; // ms

export const VLIVE_CHARACTER_THUMBNAIL_MAX_SIZE: number = 10; // 10 MB
export const VLIVE_CHARACTER_DEFAULT_SIZE: number = 35; // 35 MB
export const VLIVE_CHARACTER_DEFAULT_COUNT: number = 3;

export const VLIVE_ENABLED_MODEL_TABS_ORDER = [
  VliverModel.EASY_CREATION_BY_IMAGE,
  VliverModel.LIVE2D,
  VliverModel.VRM,
];

export const VLIVE_ENABLED_MODEL_TABS_LOKALISE_MAP = {
  [VliverModel.LIVE2D]: 'vlive_intro_2d_tab',
  [VliverModel.VRM]: 'vlive_intro_3d_tab',
  [VliverModel.EASY_CREATION_BY_IMAGE]: 'vlive_intro_pic_tab',
};

export const VLIVE_DEFAULT_VALIDATION_STATUS = {
  [VliverValidationErrorBit.MODEL3_NOT_FOUND]: false,
  [VliverValidationErrorBit.MODEL3_PARSE_FAILED]: false,
  [VliverValidationErrorBit.FILE_REFERENCES_KEY_NOT_FOUND]: false,
  [VliverValidationErrorBit.MOC_KEY_NOT_FOUND]: false,
  [VliverValidationErrorBit.TEXTURE_KEY_NOT_FOUND]: false,
  [VliverValidationErrorBit.MOC_URL_NOT_FOUND]: false,
  [VliverValidationErrorBit.TEXTURE_URL_NOT_FOUND]: false,
  [VliverValidationErrorBit.MOTION_URL_NOT_FOUND]: false,
  [VliverValidationErrorBit.PHYSICS_URL_NOT_FOUND]: false,
  [VliverValidationErrorBit.POSE_URL_NOT_FOUND]: false,
  [VliverValidationErrorBit.USER_DATA_URL_NOT_FOUND]: false,
  [VliverValidationErrorBit.EXPRESSION_URL_NOT_FOUND]: false,
  [VliverValidationErrorBit.MULTIPLE_MODEL_FOUND]: false,
};

export const MODEL_FILE_KEY = {
  MODEL3: 'Model3.json',
  MOC: 'Moc',
  TEXTURE: 'Texture',
  FILE_REFERENCES: 'FileReferences',
  MOTION: 'Motion',
  PHYSICS: 'Physics',
  POSE: 'Pose',
  USERDATA: 'Userdata',
  EXPRESSION: 'Expression',
};
