import React from 'react';

import styled from 'styled-components';

import { $t } from '@17live/app/utils';
import {
  COLOR_NEUTRAL_700,
  COLOR_PRIMARY_BLACK,
} from '@17live/design-system/color';
import Box from '@17live/ui/Box';
import IgModalVliverVrmTutorial from '@17live/ui/Gui/IgModalVliverVrmTutorial';

const Subtitle = styled.h6`
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  margin: 0 0 8px;
  color: ${COLOR_PRIMARY_BLACK};
`;

const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  margin: 0;
  color: ${COLOR_NEUTRAL_700};
  white-space: pre-wrap;
`;

const VLiveCharacterIntroductionFromAppVRM = ({
  hasTab,
}: {
  hasTab: boolean;
}) => {
  return (
    <Box mt={'24px'} p={hasTab ? '0 16px' : '0px'}>
      <Subtitle>{$t('vlive_intro_3d_title')}</Subtitle>
      <Box mb={'8px'}>
        <IgModalVliverVrmTutorial width={150} height={150} />
      </Box>
      <Box mb={'24px'}>
        <Subtitle>{$t('vlive_intro_3d_content_1')}</Subtitle>
        <Text>{$t('vlive_intro_3d_content_1_1')}</Text>
        <br />
        <Text>{$t('vlive_intro_3d_content_1_2')}</Text>
      </Box>

      <Box mb={'24px'}>
        <Subtitle>{$t('vlive_intro_3d_content_2')}</Subtitle>
        <Text>{$t('vlive_intro_3d_content_2_1')}</Text>
        <br />
        <Text>{$t('vlive_intro_3d_content_2_2')}</Text>
        <br />
        <Text>{$t('vlive_intro_3d_content_2_3')}</Text>
      </Box>

      <Box mb={'24px'}>
        <Subtitle>{$t('vlive_intro_3d_content_3')}</Subtitle>
        <Text>{$t('vlive_intro_3d_content_3_1')}</Text>
        <br />
        <Text>{$t('vlive_intro_3d_content_3_2')}</Text>
      </Box>
    </Box>
  );
};

export default VLiveCharacterIntroductionFromAppVRM;
