import React from 'react';

import styled from 'styled-components';

import { COLOR_BRAND } from '@17live/design-system/color';

const Pointer = styled.div`
  position: relative;
  display: inline-block;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: ${COLOR_BRAND};
  vertical-align: bottom;
`;

const TabPointerWrapper = styled.div<{ pos?: number }>`
  position: absolute;
  bottom: 0;
  left: ${props => props.pos || 50}%;
  width: 12px;
  height: auto;
  transform: translateX(-50%);
`;

type TabListPointerProps = {
  pos: number;
};

const TabListPointer: React.FunctionComponent<TabListPointerProps> = props => (
  <TabPointerWrapper {...props}>
    <Pointer />
  </TabPointerWrapper>
);

export default TabListPointer;
