import React from 'react';

import styled from 'styled-components';

import VLiveCharacterIntroductionFileInfoLive2D from '@17live/app/components/VLiveCharacter/VLiveCharacterIntroductionFileInfoLive2D';
import { $t } from '@17live/app/utils';
import {
  COLOR_NEUTRAL_700,
  COLOR_PRIMARY_BLACK,
} from '@17live/design-system/color';
import Box from '@17live/ui/Box';
import IgModalVliverFileTutorial from '@17live/ui/Gui/IgModalVliverFileTutorial';

const Subtitle = styled.h6`
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  margin: 0 0 8px;
  color: ${COLOR_PRIMARY_BLACK};
`;

const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  margin: 0;
  color: ${COLOR_NEUTRAL_700};
  white-space: pre-wrap;
`;

type VLiveCharacterIntroductionFromAppLive2DProps = {
  characterSizeLimitMB?: number;
  hasTab: boolean;
};

const VLiveCharacterIntroductionFromAppLive2D = ({
  characterSizeLimitMB,
  hasTab,
}: VLiveCharacterIntroductionFromAppLive2DProps) => {
  return (
    <Box mt={'24px'} p={hasTab ? '0 16px' : '0px'}>
      <Subtitle>{$t('vlive_intro_content_3')}</Subtitle>
      <Box mb={'8px'}>
        <IgModalVliverFileTutorial width={150} height={150} />
      </Box>
      <Box mb={'24px'}>
        <Subtitle>{$t('vlive_intro_content_3_1')}</Subtitle>
        <Text>{$t('vlive_intro_content_3_1_1')}</Text>
        <br />
        <Text>{$t('vlive_intro_content_3_1_2')}</Text>
      </Box>

      <Box mb={'24px'}>
        <Subtitle>{$t('vlive_intro_content_3_2')}</Subtitle>
        <Box mb={'24px'}>
          <Text>{$t('vlive_intro_content_3_2_1')}</Text>
        </Box>

        <VLiveCharacterIntroductionFileInfoLive2D
          isMobile
          characterSizeLimitMB={characterSizeLimitMB}
        />
      </Box>
    </Box>
  );
};

export default VLiveCharacterIntroductionFromAppLive2D;
