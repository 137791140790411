import React, { ComponentType, ReactNode } from 'react';

import styled, { css } from 'styled-components';

import {
  COLOR_STROKE_BLACK,
  COLOR_STROKE_WHITE,
  COLOR_STROKE_WHITE_LIGHTER,
  COLOR_TEXT_BLACK_PRIMARY,
  COLOR_TEXT_BLACK_TERTIARY,
  COLOR_TEXT_WHITE_PRIMARY,
  COLOR_TEXT_WHITE_TERTIARY,
} from '@17live/design-system/color';
import { TAB_HEIGHT } from '@17live/ui/TabList/TabList.constants';
import { calculateTrianglePosition } from '@17live/ui/TabList/TabList.utils';
import TabListPointer from '@17live/ui/TabList/TabListPointer';

type TabOption = {
  tabName: ReactNode;
  tabDecorator?: ComponentType;
};

type Tab = ReactNode | TabOption;

type Props = {
  tabs: Tab[];
  activeTab: number;
  handleClickTab: (activeTab: number) => void;
  className?: string;
};

export const Tab = styled.li<{ isActive: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) =>
    theme.isTheater ? COLOR_TEXT_WHITE_TERTIARY : COLOR_TEXT_BLACK_TERTIARY};
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  transition: color 0.2s ease-out;
  font-weight: 600;

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) =>
        theme.isTheater ? COLOR_TEXT_WHITE_PRIMARY : COLOR_TEXT_BLACK_PRIMARY};
    `}

  &:hover {
    color: ${({ theme }) =>
      theme.isTheater ? COLOR_TEXT_WHITE_PRIMARY : COLOR_TEXT_BLACK_PRIMARY};
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1px;
    height: 16px;
    background-color: ${({ theme }) =>
      theme.isTheater ? COLOR_STROKE_BLACK : COLOR_STROKE_WHITE};
  }

  &:last-of-type {
    &:after {
      content: none;
    }
  }
`;

export const TabInner = styled.div`
  padding: 1em;
`;

export const TabListWrapper = styled.ul`
  display: flex;
  width: 100%;
  font-size: 14px;
  justify-content: center;
  padding: 0;
  margin: 0;
  position: relative;
  background-color: ${props => props.theme.BG_LIGHTER};
  border-bottom: 1px solid
    ${({ theme }) =>
      theme.isTheater ? COLOR_STROKE_BLACK : COLOR_STROKE_WHITE};

  flex-shrink: 0;
  height: ${TAB_HEIGHT}px;
`;

const isTabOption = (tab: Tab): tab is TabOption => !!tab?.tabName;

const TabList: React.FunctionComponent<Props> = ({
  tabs,
  activeTab,
  handleClickTab,
  className,
}) => (
  <TabListWrapper className={className}>
    {tabs.map((tab, index) => {
      const { tabName, tabDecorator: TabDecorator = null } = isTabOption(tab)
        ? tab
        : { tabName: tab };
      const innerTab = <TabInner>{tabName}</TabInner>;
      return (
        <Tab
          key={index}
          isActive={activeTab === index}
          onClick={() => handleClickTab(index)}
        >
          {TabDecorator ? <TabDecorator>{innerTab}</TabDecorator> : innerTab}
        </Tab>
      );
    })}
    <TabListPointer pos={calculateTrianglePosition(activeTab, tabs.length)} />
  </TabListWrapper>
);

export default TabList;
